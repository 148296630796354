import { store } from '@/store'
import { waitWithTimeout } from '@/util/native/helpers'
import { runRequestBeforeHook, getHeader } from '@/util/native/helpers/request'
import { runEvaluateBeforeHook } from '@/util/native/helpers/evaluate'
import { userAgent } from '../client-side-automation'

export async function show({ handle }) {
  const page = await _getPage(handle)
  await page.show()
}

export async function hide({ handle }) {
  const page = await _getPage(handle)
  await page.hide()
}

export async function url({ handle }) {
  const page = await _getPage(handle)
  return page.url()
}

export async function evaluate({ handle, func, args }) {
  const page = await _getPage(handle)

  // The page.evaluate function allows for an array of args, but services only sends a single
  // argument, so execute the beforeHook against this single argument only if the argument is an
  // object that includes a beforeHook
  if (args[0]?.beforeHook) {
    args[0] = await runEvaluateBeforeHook({
      store,
      params: args[0],
      pageHandle: handle
    })
  }

  const code = `async () => {
    try {
      return await (${func}).apply(null, ${JSON.stringify(args || [])})
    } 
    catch (error) {
      return { evaluateError: { message: error.message, stack: error.stack } }
    }
  }`

  return page.evaluate(code)
}

export async function cookies({ handle, url }) {
  const page = await _getPage(handle)
  return page.cookies(url)
}

export async function wait({ handle, func, options }) {
  const page = await _getPage(handle)

  return await waitWithTimeout({
    func: async () => page.evaluate(func, options.args),
    options
  })
}

export async function navigate({ handle, url, options }) {
  const page = await _getPage(handle)
  await page.goto(url, options)
}

export async function request({ handle, options }) {
  const page = await _getPage(handle)

  options = await runRequestBeforeHook({
    store,
    request: options,
    pageHandle: handle
  })

  if (options.data && typeof options.data === 'object') {
    options.data = JSON.stringify(options.data)
  }

  if (!options.headers) options.headers = {}

  if (!getHeader(options.headers, 'User-Agent')) {
    options.headers['User-Agent'] = userAgent()
  }

  return page.request(options)
}

async function _getPage(handle) {
  return store.dispatch('userDeviceAutomation/getPage', handle)
}
